/** Inter Font */
@font-face {
  font-display: swap;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  src: url('fonts/Inter/Inter-Regular.woff') format('woff');
}

@font-face {
  font-display: swap;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  src: url('fonts/Inter/Inter-Medium.woff') format('woff');
}

@font-face {
  font-display: swap;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  src: url('fonts/Inter/Inter-SemiBold.woff') format('woff');
}

/** Color pallete */
$gray: #6c757d;

/** loader animation */
.loader-root {
  text-align: center;
}

.loader {
  align-items: center;
  display: flex;
  font-size: 28px;
  font-style: italic;
  font-weight: 600;
  height: 100vh;
  padding-left: 20px;
  position: relative;
}

.loader:before {
  animation: loading 2s linear infinite;
  content: attr(data-text);
  left: 20px;
  max-width: 180px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
}

@keyframes loading {
  0% {
    max-width: 0;
  }
}

.loader-light {
  color: rgba(0, 0, 0, .3);
}

.loader-light:before {
  color: rgba(0, 0, 0, .5);
}

.loader-dark {
  background-color: rgba(0, 0, 0, .8);
  color: rgba(255, 255, 255, .3);
}

.loader-dark:before {
  color: rgba(255, 255, 255, .5);
}

/** Bargain Bot animation */
.spinner > div {
  width: 8px;
  height: 8px;
  margin: 4px;
  background-color: #FFDF82;

  border-radius: 100%;
  display: inline-block;
  -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
  animation: sk-bouncedelay 1.4s infinite ease-in-out both;
}

.spinner .bounce1 {
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}

.spinner .bounce2 {
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}

@-webkit-keyframes sk-bouncedelay {
  0%, 80%, 100% { -webkit-transform: scale(0) }
  40% { -webkit-transform: scale(1.0) }
}

@keyframes sk-bouncedelay {
  0%, 80%, 100% { 
    -webkit-transform: scale(0);
    transform: scale(0);
  } 40% { 
    -webkit-transform: scale(1.0);
    transform: scale(1.0);
  }
}

.swoopInLeft {
  -webkit-animation-duration: 0.3s;
  -webkit-animation-name: swoopInLeft;
  animation-duration: 0.3s; 
  animation-name: swoopInLeft;
}

@keyframes swoopInLeft {
  0% {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    -webkit-transform: scaleX(1.5) translate3d(-400px, 0, 0);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    opacity: 0;
    transform: scaleX(1.5) translate3d(-400px, 0, 0); }

  40% {
    -webkit-animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    -webkit-transform: scaleX(1.2) translate3d(0, 0, 0);
    animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    opacity: 1;
    transform: scaleX(1.2) translate3d(0, 0, 0); }

  65% {
    -webkit-transform: scaleX(1) translate3d(20px, 0, 0);
    transform: scaleX(1) translate3d(20px, 0, 0); }

  100% {
    -webkit-animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    -webkit-transform: scaleX(1) translate3d(0, 0, 0);
    animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    transform: scaleX(1) translate3d(0, 0, 0); 
  } 
}

.swoopInRight {
  -webkit-animation-duration: 0.5s;
  -webkit-animation-name: swoopInRight;
  animation-duration: 0.5s; 
  animation-name: swoopInRight;
}

@keyframes swoopInRight {
  0% {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    -webkit-transform: scaleX(1.5) translate3d(400px, 0, 0);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    opacity: 0;
    transform: scaleX(1.5) translate3d(400px, 0, 0); }

  40% {
    -webkit-animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    -webkit-transform: scaleX(1.2) translate3d(0, 0, 0);
    animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    opacity: 1;
    transform: scaleX(1.2) translate3d(0, 0, 0); }

  65% {
    -webkit-transform: scaleX(1) translate3d(-20px, 0, 0);
    transform: scaleX(1) translate3d(-20px, 0, 0); }

  100% {
    -webkit-animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    -webkit-transform: scaleX(1) translate3d(0, 0, 0);
    animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    transform: scaleX(1) translate3d(0, 0, 0); 
  } 
}

/** gradient box */
.gradient-box {
  border-radius: 12px;
  position: relative;
  &:before {
    position: absolute;
    background: linear-gradient(45deg, #fc0, #c20d3a);
    border-radius: inherit;
    top: -0.5px; right: -0.5px; bottom: -0.5px; left: -0.5px;
    content: '';
    z-index: -1;
  }
}


/** Component styles */
html {
  scroll-behavior: smooth;
}

body {
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  -webkit-overflow-scrolling: touch;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  font-family: Inter;
  margin: 0;
  padding: 0;
  user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
}

#root {
  height: 100%;
  background-color: #424242;
}

::-webkit-scrollbar {
  width: 0;
}

/** React toast */
.Toastify__toast-body {
  font-size: 18px;
}

.Toastify__toast {
  border-radius: 5px !important;
  margin: 16px;
  margin-bottom: 10px !important;
  min-height: 50px !important;
  padding: 15px !important;
}

/** Material Ui defaults */
.MuiButton-label {
  text-transform: none !important;
}
